<template>
    <el-dialog :close-on-click-modal="false" title="新增定时任务" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">


            <el-form-item label="任务标题" prop="title">
                <el-input v-model="value.title" placeholder="请输入任务标题" />
            </el-form-item>

            <el-form-item label="任务类型">
                <el-select v-model="valueSEL" placeholder="请选择任务类型">
                    <el-option v-for="item in options" :key="item.valueKEY" :label="item.label" :value="item.valueKEY">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="任务脚本" prop="script">
                <el-input v-model="value.script" placeholder="请输入任务脚本" />
            </el-form-item>


            <el-form-item label="最多执行" prop="most">
                <el-input v-model="value.most" placeholder="请输入最多执行次数" />
            </el-form-item>


            <el-form-item label="任务权重" prop="weight">
                <el-input v-model="value.weight" placeholder="请输入任务权重" />
            </el-form-item>


            <el-form-item label="开始时间" prop="star">
                <el-date-picker v-model="star" type="date" placeholder="选择日期">
                </el-date-picker>
            </el-form-item>


            <el-form-item label="结束时间" prop="end">
                <el-date-picker v-model="end" type="date" placeholder="选择日期">
                </el-date-picker>
            </el-form-item>


            <el-form-item label="任务内容" prop="info">
                <el-input autosize type="textarea" :rows="2" placeholder="请输入任务内容" v-model="value.info" />
            </el-form-item>



            <el-form-item label="状态" prop="state">
                <el-switch v-model="state" active-color="#5BD995" active-text="显示" inactive-color="#D9D9D9"
                    inactive-text="隐藏" class="switchSelfBox">
                </el-switch>
            </el-form-item>


            <el-form-item label="序号" prop="order">
                <el-input v-model="value.order" placeholder="请输入序号" />
            </el-form-item>





        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="refresh">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,


                state: true,


                options: [{
                    valueKEY: '选项1',
                    label: '天津南开卫健委'
                }, {
                    valueKEY: '选项2',
                    label: '天津西青卫健委'
                }, ],
                valueSEL: '',

                script: "",
                most: "",
                weight: "",
                star: "",
                end: "",
                info: "",
                state: true,
                order: ""



            }
        },
        methods: {
            open() {
                this.formShow = true
                this.show = true
            },
            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },


        }
    }
</script>

<style>
    /* 	.selfInputBoxIV .el-input__inner[placeholder="请选择任务类型"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .selfInputBoxIV .el-input__prefix {
        margin-left: 20px;
    }

    .selfInputBoxIV .el-date-editor.el-input.el-input--mini.el-input--prefix.el-input--suffix.el-date-editor--date {
        /* border: 1px red solid !important; */
        box-sizing: content-box !important;
        width: 100%;
    }
</style>