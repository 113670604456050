<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true" :model="search">

                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="getList" v-model="search.title" placeholder="请输入关键字" />
                </el-form-item>

                <el-form-item prop="title">
                    <el-select v-model="value1" clearable placeholder="请选择入库人" class="SelfSelect">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getList(data.size)">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <el-button type="primary" @click="toSearch" v-if="getPurviewNew('sys:adminlog:drop')">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加任务
                    </el-button>


                </el-form-item>


            </el-form>





        </div>

        <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
            :get-list="getList" stripe border>

            <el-table-column show-overflow-tooltip prop="id" label="ID" align="left" />
            <el-table-column show-overflow-tooltip prop="title" label="任务标题" align="left" />
            <el-table-column show-overflow-tooltip prop="type" label="任务类型" align="left" />
            <el-table-column show-overflow-tooltip prop="script" label="任务脚本" align="left" />
            <el-table-column show-overflow-tooltip prop="most" label="最多执行" align="left" />
            <el-table-column show-overflow-tooltip prop="times" label="执行次数" align="left" />
            <el-table-column show-overflow-tooltip prop="weight" label="权重" align="left" />
            <el-table-column show-overflow-tooltip prop="state" label="状态" align="left" width="90">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.state" active-color="#5BD995" active-text="正常"
                        inactive-color="#D9D9D9" inactive-text="禁用" class="switchSelfBox">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="star" label="开始时间" align="left" />
            <el-table-column show-overflow-tooltip prop="end" label="结束时间" align="left" />
            <el-table-column show-overflow-tooltip prop="final" label="最后执行时间" align="left" />

        </Fanfu-Table>

        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />


    </div>
</template>

<script>
    import Search from './components/TaskSearch'


    import resizeDetector from 'element-resize-detector'


    const list = [{
            id: "001",
            title: "任务名",
            type: "任务类型",
            script: "脚本",
            most: "post",
            times: "100",
            weight: "99%",
            state: true,
            star: "2022-7-8 15:02:14",
            end: "2022-7-8 15:02:24",
            final: "2022-7-8 15:02:34",




        },

    ]
    export default {
        components: {
            Search,

        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },



                options: [{
                    value: '选项1',
                    label: '天津南开卫健委1'
                }, {
                    value: '选项2',
                    label: '天津西青卫健委2'
                }, ],
                value1: '',









                loading: false,
                select: [],
                data: {
                    list,
                    page: 1,
                    size: 15,
                    total: list.length
                }
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList(size) {
                if (size) {
                    this.data.page = 1
                    this.data.size = size
                }
                this.loading = true
                this.select = []
                this.$nextTick(() => {
                    const start = this.data.size * (this.data.page - 1)
                    const end = start + this.data.size
                    this.data.total = list.length
                    const data = []
                    for (let i = start; i < end; i++) {
                        if (list[i]) {
                            data.push(list[i])
                        }
                    }
                    this.data.list = data
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)

                    setTimeout(() => {
                        this.modifyLayout();
                    }, 1100)


                })
            },
            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },



            toSearch() {
                this.$refs.searchRef.open()
            },

            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {

                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');

                    }


                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {

                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }

                });

            }

        },

        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();
        }

    }
</script>
<style>

</style>